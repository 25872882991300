<template>
  <main class="tour-person w-100">
    <section class="tour-person__container">
      <h1 class="title tour-person__title">{{ $t("Passport information verification") }}</h1>
      <p class="text tour-person__text">{{ $t("Please check the passenger information taken") }}</p>

      <form class="tour-person__form" action>
        <div class="tour-person__item">
          <label class="label tour-person__label">{{ $t("First name in English") }}</label>
          <b-form-input
              v-model="$v.form.first_name.$model"
              :state="validateState('first_name')"
              aria-describedby="input-firstName-feedback"
              class="input tour-person__input"
            ></b-form-input>
          <b-form-invalid-feedback id="input-firstName-feedback">{{ $t("This is a required field.") }}</b-form-invalid-feedback>
        </div>

        <div class="tour-person__item">
          <label class="label tour-person__label">{{ $t("Last name in English") }}</label>
           <b-form-input
              v-model="$v.form.last_name.$model"
              :state="validateState('last_name')"
              aria-describedby="input-last_name-feedback"
              class="input tour-person__input"
            ></b-form-input>
          <b-form-invalid-feedback id="input-last_name-feedback">{{ $t("This is a required field.") }}</b-form-invalid-feedback>
        </div>

        <div class="tour-person__item">
          <label class="label tour-person__label">{{ $t("Number of passport") }}</label>
          <b-form-input
              v-model="$v.form.passport_number.$model"
              :state="validateState('passport_number')"
              aria-describedby="input-passport_number-feedback"
              class="input tour-person__input"
            ></b-form-input>
          <b-form-invalid-feedback id="input-passport_number-feedback">{{ $t("This is a required field.") }}</b-form-invalid-feedback>
        </div>

        <div class="tour-person__item">
          <label class="label tour-person__label">{{ $t("ID number") }}</label>
          <input class="input tour-person__input" type="text" v-model="form.id_number" />
        </div>

        <div class="tour-person__item tour-person__item-calendar cus-datepicker-div">
          <label class="label tour-person__label">{{ $t("Validity") }}</label>
          <Datepicker 
            v-model="form.validity" 
            :format="format"
            class="input tour-person__input cus-datepicker"
            :language="languages[currentLang]"
          ></Datepicker>
        </div>

        <div class="tour-person__item tour-person__item-calendar cus-datepicker-div">
          <label class="label tour-person__label">{{ $t("Date of birth") }}</label>
          <Datepicker 
            v-model="form.birthday" 
            class="input tour-person__input cus-datepicker" 
            :format="format"
            :language="languages[currentLang]"
          ></Datepicker>
          <p :class="form.birthday == '' ? 'invalid-desc' : 'valid-desc'">{{ $t("This is a required field.") }}</p>
        </div>

        <div class="tour-person__item">
          <label class="label tour-person__label">{{ $t("A country in which the passport was issued") }}</label>
          <input class="input tour-person__input" type="text" v-model="form.passport_country" />
        </div>

        <div class="tour-person__item">
          <label class="label tour-person__label">{{ $t("Gender") }}</label>
          <div class="tour-person__item-wrapper">
            <button v-bind:class="[form.gender == 'M' ? 'tour-person__sex_active' : '', 'button tour-person__sex']" @click="selectGender('M')" ref="male">{{ $t("Man") }}</button>
            <button v-bind:class="[form.gender == 'F' ? 'tour-person__sex_active' : '', 'button tour-person__sex']" @click="selectGender('F')" ref="female">{{ $t("Wife") }}</button>
          </div>
        </div>
      </form>

      <a class="link tour-person__link" href="#" @click="saveData()">{{ $t("Approval") }}</a>

      <button class="button tour-person__repeat">{{ $t("Shoot again") }}</button>
    </section>
  </main>
</template>

<script>
import "bootstrap";
import "../../common/plugins/bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";
export default {
  mixins: [validationMixin],
  name: "PassportDetail",
  components: {
  },
  computed: {
    ...mapState({
      passportOneManualData: state => state.tour_order.passportOneManualData,
      passportImageTempData: state => state.tour_order.passportImageTempData,
      passportOneData: state => state.tour_order.passportOneData,
      passportData_receipts: state => state.tour_order.passportData_receipts,
      passportManualData: state => state.tour_order.passportManualData
    }),
    currentLang: function() {
      return localStorage.language;
    }
  },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        passport_number: "",
        id_number: "",
        validity: "",
        birthday: "",
        passport_country: "",
        gender: "M"
      },
      pagetype: "",
      format: "d MMMM yyyy",
      currentNum: 0
    }
  },
  validations: {
    form: {
      first_name: {
        required
      },
      last_name: {
        required
      },
      passport_number: {
        required
      },
    }
  },
  async created() {
    if(this.$route.params.id == "confirm") {
      this.pagetype = "isConfirm";
      // let temp = Object.assign({}, this.passportImageTempData[0]);
      this.form = this.passportImageTempData[0];
      this.currentNum = 1;
    }
    else if(this.$route.params.id == "editUploaded") {
      this.pagetype = "isEditUploaded";
      this.form = this.passportOneData;
    }
    else if (this.$route.params.id != "create") {
      this.pagetype = "isEdit";
      const passportId = this.$route.params.id;
      await this.getOneManualData({id: passportId});
      let temp = Object.assign({}, this.passportOneManualData);
      this.form = temp;
    } 
  },
  methods: {
    ...mapActions("tour_order", {
      addManualData: "addManualData",
      getOneManualData: "getOneManualData",
      updatePassportMData: "updatePassportMData",
      updatePassportData: "updatePassportData",
      addUploadedData: "addUploadedData"
    }),
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    selectGender(gender) {
      this.form.gender = gender;
    },
    saveData() {
      this.$v.form.$touch();
      if (
        this.$v.form.first_name.$anyError ||
        this.$v.form.last_name.$anyError ||
        this.$v.form.passport_number.$anyError ||
        this.form.birthday == ''
      ) {
        return;
      }

      if(this.pagetype == "isEdit") {
        this.updatePassportMData({id: this.passportOneManualData.passport_number, mdata: this.form});
          const id = localStorage.getItem("transactionId");
          const phoneNumber = localStorage.getItem("phone_number");
          this.$router.push({ name: 'order-details', params: { transaction_id: id, phone_number: phoneNumber } });
      }
      else if(this.pagetype == "isConfirm") {
        if(this.currentNum < this.passportImageTempData.length) {
          this.form = this.passportImageTempData[this.currentNum];
          this.currentNum = this.currentNum + 1;
        } else {
          this.addUploadedData();
        }
      }
      else if(this.pagetype == "isEditUploaded") {
        this.updatePassportData();
      } else {
        const roomId = localStorage.getItem("roomId");
        this.addManualData({roomId: roomId, mdata: this.form});

        const id = localStorage.getItem("transactionId");
        const phoneNumber = localStorage.getItem("phone_number");
        this.$router.push({ name: 'order-details', params: { transaction_id: id, phone_number: phoneNumber } });
      }
    }
  },
  mounted() {
    moduleTourPerson.init();
  }
};
</script>

<style lang="scss" scoped>
.input {
  width: 100%;
  font-family: Open Sans Hebrew;
  font-size: 18px;
  color: #002036;
  outline: 0;
  height: 44px;
  line-height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #b2bbc2;
  border-radius: 0;
}
.valid-desc {
  display: none;
}
.invalid-desc {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>